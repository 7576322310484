<template>
    <div class="content-page">
        <div class="container-fluid">
            <div class="row" v-if="permissions">
                <div class="col-lg-12">
                    <div class="type_package" v-if="see_form == true">
                        <form  class="form" action="" @submit.prevent="importmenu">
                            Click here if you don't have a model <a :href="URL_API+'users-export'" class="btn m-10 btn-primary add-list" ><i class="las la-plus mr-3"></i>Export model</a>
                            <br><br>
                            <input class="btn col-lg-9 mr-3 btn-primary add-list" type="file" name="" id="file" ref="photos" >
                            <button class="btn m-10 btn-primary add-list" type="submit">OK</button><br><br>
                            <button class="btn m-10 btn-primary add-list pull-right" @click="back">Retour</button>
                        </form>
                    </div>
                    <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
                        <div>
                            <h4 class="mb-3">Liste des utilisateurs</h4>
                            
                        </div>
                        <a href="/create-user" v-if="permissions.includes('create-users')" class="btn btn-primary add-list" ><i class="las la-plus mr-3"></i>Ajouter un utlisateur</a>
                        <a href="/search-user" v-if="permissions.includes('show-users')" class="btn btn-primary add-list" ><i class="las la-plus mr-3"></i>trouver un utilisateur</a>
                        <!-- <a @click="see" v-if="permissions.includes('show-users')" class="btn btn-primary add-list" ><i class="las la-plus mr-3"></i>Add users by file</a> -->
                        <!-- <a @click="resetU" v-if="permissions.includes('reset-balance')" class="btn btn-primary add-list" ><i class="las la-plus mr-3"></i>Reset all balances</a> -->
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="table-responsive rounded mb-3">
                    <table id="example" class="data-table table mb-0 tbl-server-info">
                        <thead class="bg-white text-uppercase">
                            <tr class="ligth ligth-data">
                                <th>ID</th>
                                <th>Email</th>
                                <th>Nom</th>
                                <th>Prenoms</th>
                                <th>Contact</th>
                                <th>Profil</th>
                                <th v-if="permissions.includes('show-users') || permissions.includes('delete-users')">Actions</th>
                            </tr>
                        </thead>
                        <tbody class="ligth-body">
                            <tr v-for="(user,index) in users" :key="index">
                                <td>{{user.matricule}}</td>
                                <td>{{user.email}}</td>
                                <td>{{user.nom}}</td>
                                <td>{{user.prenoms}}</td>  
                                <td>{{user.phone}}</td> 
                                <td> {{ user.function }} </td>
                                <td v-if="permissions.includes('show-users') || permissions.includes('delete-users')">
                                    <div class="d-flex align-items-center list-action">
                                        <router-link :to="'/show-user/'+user.id" v-if="permissions.includes('show-users')"
                                                    class="badge badge-info mr-2" data-toggle="tooltip" data-placement="top" 
                                                    title="" data-original-title="View"><i class="fa fa-eye mr-0"></i>
                                        </router-link>

                                        <button v-if="permissions.includes('delete-users')"  class="badge badge-info bg-warning mr-2 btn" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete"
                                            @click="destroy(user.id)"><i class="fa fa-trash mr-0"></i></button>
                                        <button v-if="permissions.includes('delete-users') && user.active == 0" type="btn"  class="badge badge-info bg-secondary btn mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete"
                                            @click="active(user.id,1)"><i class="fa fa-lock mr-0"></i></button>
                                        <button v-if="permissions.includes('enable/disable accounts') && user.active == 1" type="btn"  class="badge badge-info bg-secondary btn mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete"
                                            @click="active(user.id,0)"><i class="fa fa-unlock mr-0"></i></button>
                                    </div>
                                </td>
                            </tr>
                            
                            
                        </tbody>
                    </table>
                </div>
                <div class="vld-parent">
                    <loading :active.sync="isLoading" 
                    :can-cancel="true" 
                    :is-full-page="fullPage"></loading>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import {URL_API} from '@/config'
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2'
import $ from "jquery"
export default {
    components: {
            Loading
    },
    data() {
        return {
            permissions: [],
            users:{},
            isLoading: false,
            fullPage: true,
            see_form: false,
            URL_API: URL_API
        };
    },
    mounted(){
        this.$store.state.user.permissions.forEach(element => {
            this.permissions.push(element.name)
        });
        this.getusers()
    },
    methods:{
        exportmodel () {
            axios.get(URL_API+'user-export')
                        .then(function (reponse){
                            Swal.fire('Success', 'Menu added', 'success')
                            console.log(reponse);
                        })
                        .catch(function (error){
                            Swal.fire('Failure', 'An error occured, menu not added', 'error')
                            console.log(error);
                        })
        },
        see(){
            this.see_form = true
        },
        back(){
            this.see_form = false
        },
        importmenu(){
                    let app = this
                    app.isLoading = true
                    var menuData = new FormData();
                    app.photos = app.$refs.photos.files
                    let file = app.photos[0];
                    console.log(file)
                    if (file != null) {
                        menuData.append('file', file);
                        axios.post(URL_API+'users-excel', menuData,{
                            headers: {"Access-Control-Allow-Origin": "*"}

                        })
                        .then(function (reponse){
                            app.isLoading = false
                            Swal.fire('Success', 'Users added', 'success')
                            console.log(reponse);
                        })
                        .catch(function (error){
                            app.isLoading = false
                            Swal.fire('Failure', 'An error occured, users not added', 'error')
                            console.log(error);
                        })
                        app.see_form = false
                    }
        },
        async resetU () {
            const { value: fruit } = await Swal.fire({
                title: 'Select field validation',
                input: 'select',
                inputOptions: {
                    'Student': 'Student',
                    'Staff': 'Staff',
                },
                inputPlaceholder: 'Select a profile',
                showCancelButton: true,
                inputValidator: (value) => {
                    return new Promise((resolve) => {
                    if (value) {
                        resolve()
                    } else {
                        resolve('Choose a profile')
                    }
                    })
                }
            })

            if (fruit) {
                axios.post(URL_API+'resetUser', {role: fruit})
                .then(function (rep) {
                    console.log(rep);
                    if (rep.data.status == true) {
                        Swal.fire('Successful', 'Balances reset', 'success')
                    } else {
                        Swal.fire('Warning', rep.data.message, 'warning')
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    Swal.fire('Error', 'An unknown error occured', 'error')
                })
            }
        },
        getusers() {
            this.isLoading =  true
            axios.get(URL_API+'users')
            .then(response => {
                console.log(response)
                if (response.data.status == true) {
                    $(document).ready( function () {
                            $('#example').DataTable(
                                {
                                    "lengthMenu": [ 5,10, 25, 50, 75, 100 ],
                                    "pageLength": 10,
                                    'order':[],
                                    stateSave: true,
                                    "bDestroy": true
                                }
                            );
                        } );
                this.users = response.data.data
                this.isLoading =  false
                }
            })
            .catch(error => {
                console.log(error)
                this.errored = true
                Swal.fire(
                        'Error!',
                        'An error occured while fetching datas',
                        'error'
                )
                this.isLoading =  false
            })
        },
        destroy(id) {
            Swal.fire({
                title: 'Do you want to delete this user?',
                text: "This action is irreversible!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.isLoading =  true
                    axios.delete(URL_API+'users/'+id)
                    .then(response => {
                        console.log(response.data)
                        this.users = response.data
                        this.isLoading =  false
                        Swal.fire(
                            'Deleted!',
                            'The user was deleted.',
                            'success'
                        )
                        location.reload()
                    })
                    .catch(error => {
                        console.log(error)
                        this.errored = true
                        this.isLoading =  false
                    })
                }
            })
            
        },
        active(userID,id) {
            let app = this
            Swal.fire({
                title: 'do you want to enable/disable this account?',
                text: "This action is irreversible!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.isLoading =  true
                    axios.put(URL_API+'users-status/'+userID,{
                        active:id
                    })
                    .then(response => {
                        console.log(response.data)
                        this.isLoading =  false
                        if (id == 0) {
                            Swal.fire(
                                'Success!',
                                'account Disabled',
                                'success'
                            )
                        } else {
                            Swal.fire(
                                'Success!',
                                'activated account',
                                'success'
                            )
                        }
                        
                        app.getusers()
                    })
                    .catch(error => {
                        console.log(error)
                        this.errored = true
                        this.isLoading =  false
                    })
                }
            })
            
        },
    }
}
</script>