<template>
    <div class="content-page">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
                        <div>
                            <h4 class="mb-3">Prestations </h4>
                            
                        </div>
                        <router-link to="/create-prestation" class="btn btn-primary add-list" ><i class="las la-plus mr-3"></i>Ajouter une prestation</router-link>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="table-responsive rounded mb-3">
                    <table id="example" class="data-table table mb-0 tbl-server-info">
                        <thead class="bg-white text-uppercase">
                            <tr class="ligth ligth-data">
                                <th>Libelle </th>
                                <th>Date</th>
                                <th>Nb de personne</th>
                                <th>Cout</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody class="ligth-body">
                            <tr v-for="(prestation,index) in prestations" :key="index">
                                <td>{{prestation.libelle}}</td>
                                <td>{{prestation.date}}</td> 
                                <td>{{prestation.nb_personne}}</td> 
                                <td>{{prestation.cout}}</td> 
                                <td>
                                    <div class="d-flex align-items-center list-action">
                                        
                                        <router-link :to="'/show-prestation/'+prestation.id" 
                                                    class="badge badge-info mr-2" data-toggle="tooltip" data-placement="top" 
                                                    title="" data-original-title="View"><i class="fa fa-eye mr-0"></i>
                                        </router-link>
                                        
                                        <button  class="btn bg-warning mr-2" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete"
                                            @click="destroy(prestation.id)"><i class="fa fa-trash mr-0"></i></button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
            <!-- Page end  -->
        </div>
        <!-- Modal Edit -->
        <div class="vld-parent">
                <loading :active.sync="isLoading" 
                :can-cancel="true" 
                :is-full-page="fullPage"></loading>
            </div>
    </div>
</template>
<script>
import axios from "axios";
import {URL_API} from '@/config'
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2'
//import $ from "jquery"
export default {
    components: {
            Loading
    },
    data() {
        return {
            perms: [],
            prestations:{},
            isLoading: false,
            fullPage: true,
        };
    },
    mounted(){
        this.$store.state.user.permissions.forEach(element => {
            this.perms.push(element.name)
        });
        this.getprestations()
    },
    methods:{
        getprestations(){
            let app = this
            axios.get(URL_API+'prestations')
            .then(function (reponse){
                console.log(reponse);
                if (reponse.data.status == true) {
                    app.prestations = reponse.data.data
                }
            })
            .then(function (error){
                console.log(error);
            })
        },
        destroy(id) {
            Swal.fire({
                title: 'Do you want to delete this prestation ?',
                text: "This action is irreversible!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, deleted!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.isLoading =  true
                    axios.delete(URL_API+'prestations/'+id)
                    .then(response => {
                        console.log(response.data)
                        this.isLoading =  false
                        Swal.fire(
                            'Deleted!',
                            'The prestation was deleted.',
                            'success'
                        )
                        location.reload()
                    })
                    .catch(error => {
                        console.log(error)
                        this.errored = true
                        this.isLoading =  false
                    })
                    
                }
            })
            
        },
    }
}
</script>