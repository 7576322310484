<template>
    <div class="content-page">
        <div class="container-fluid add-form-list">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        
                        <div class="card-body">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Details</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" v-if="permsi.includes('edit-users') && (user.id != stored.state.user.id)">Recharger</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="other-tab" data-toggle="tab" href="#other" role="tab" aria-controls="other" aria-selected="false" v-if="permsi.includes('index-credits')">Les rechargements</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="another-tab" data-toggle="tab" href="#another" role="tab" aria-controls="another" aria-selected="false" v-if="permsi.includes('create-permission') && (user.id != stored.state.user.id) && (this.user.function == 'Staff' || this.user.function == 'Operator' || this.user.function == 'Manager')" >Assign permissions</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="another1-tab" data-toggle="tab" href="#another1" role="tab" aria-controls="another1" aria-selected="false" v-if="permsi.includes('index-tickets')">Tickets</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div class="col-lg-12">
                                    
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>ID *</label>
                                                <input type="email" v-model="user.matricule" class="form-control" placeholder="XXXXXXXX" readonly>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                        <div class="form-group">
                                            <label>First name *</label>
                                            <input type="text" v-model="user.prenoms" class="form-control" placeholder="Enter libelle" readonly>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Last name *</label>
                                            <input type="text" v-model="user.nom" class="form-control" placeholder="Enter libelle" readonly>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Card number *</label>
                                            <input type="text" v-model="user.num_carte" class="form-control" placeholder="Enter card number" readonly>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Phone *</label>
                                                <input type="text" v-model="user.phone" class="form-control" placeholder="Enter libelle" readonly>                        
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>E-mail *</label>
                                                <input type="email" v-model="user.email" class="form-control" placeholder="Enter libelle" readonly>                        
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Solde *</label>
                                            <input type="text" v-model="user.solde" class="form-control" readonly>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Code *</label>
                                            <input type="text" v-model="user.code_qr" class="form-control" readonly>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
                                     <div class="col-md-6">
                                        <div class="form-group">
                                            <label>Departement *</label>
                                            <input type="text" v-model="user.function" class="form-control" readonly>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
                                                           
                                </div>                            
                                <button v-if="permsi.includes('edit-users')"  type="submit" @click="store" class="btn btn-primary mr-2">Edit</button>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div class="col-lg-12">
                                    <div class="row">
                                    <div class="col-md-6" >
                                        <div class="form-group">
                                            <label>Amount *</label>
                                                <input type="text" v-model="payload.montant" class="form-control" placeholder="Enter libelle">                        
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
                                
                                       
                                </div>  
                                    <button @click="recharger" class="btn btn-danger">Credit</button>
                                </div>                        
                            </div>
                            <div  class="tab-pane fade" id="other" role="tabpanel" aria-labelledby="other-tab">
                                <div class="col-lg-12">
                                    <table id="example" class="data-table table mb-0 tbl-server-info">
                                        <thead class="bg-white text-uppercase">
                                            <tr class="ligth ligth-data">
                                                <th>#</th>
                                                <th>Amount</th>
                                                <th>Pack</th>
                                            </tr>
                                        </thead>
                                        <tbody class="ligth-body">
                                            <tr v-for="(recharge,index) in user.recharges" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ recharge.montant }} XOF</td>  
                                                <td >  </td>   
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>                
                            </div>
                            <div  class="tab-pane fade" id="another" role="tabpanel" aria-labelledby="another-tab">
                                <form @submit.prevent="assign" data-toggle="validator">
                                    
                                    <div class="row">
                                            <div>
                                                <input type="text" min="0" v-model="user.function"  class="form-control" placeholder="" readonly>
                                                <div class="help-block with-errors">
                                                </div>
                                                <div class="ml-5 row" v-if="user.permissions != null">
                                                    <div v-for="(init, index) in initials" :key="index" class="col-md-4">
                                                        <div v-if="perms.includes(init.name) == true">
                                                            <input type="checkbox" checked name="perms" :value="init.name" > {{ init.name }}
                                                        </div>
                                                        <div v-else>
                                                            <input type="checkbox" name="perms" :value="init.name" > {{ init.name }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                       
                                    </div>                            
                                    <button class="btn btn-primary mr-2"><i class="las la-plus mr-3"></i>Assigner</button> 

                                </form>
                            </div>
                            <div  class="tab-pane fade" id="another1" role="tabpanel" aria-labelledby="another1-tab">
                                <div class="col-lg-12">
                                    <table id="tickets" class="data-table table mb-0 tbl-server-info">
                                        <thead class="bg-white text-uppercase">
                                            <tr class="ligth ligth-data">
                                                <th>#</th>
                                                <th>Code</th>
                                                <th>Quantité</th>
                                                <th>Consommé</th>
                                                <th>Date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody class="ligth-body">
                                            <tr v-for="(ticket,index) in user.tickets" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ ticket.code }}</td>  
                                                <td> {{ ticket.panier.length }} </td> 
                                                <td v-if="ticket.status == 1"> Oui </td>   
                                                <td v-else> Non </td>
                                                <td> {{ ticket.created_at.split('T')[0] }} </td>
                                                <td> 
                                                    <div class="d-flex align-items-center list-action">
                                                        <button @click="modal(ticket.panier)"  class="badge badge-info bg-warning mr-2 btn" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-eye mr-0"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>    
                            </div>
                        </div>
                            
                            <!-- <form  action="#" data-toggle="validator"> -->
                                
                                 
                                
                                
                            <!-- </form> -->
                        </div>
                    </div>
                    <div class="vld-parent">
                        <loading :active.sync="isLoading" 
                        :can-cancel="true" 
                        :is-full-page="fullPage"></loading>
                    </div>
                    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Menu</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body" v-if="panier.length > 0">
                                <table class="table">
                                    <thead>
                                        <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">libelle</th>
                                        <th scope="col">Qte</th>
                                        <th scope="col">Prix</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(pan,index) in panier" :key="index">
                                            <th scope="row">{{index+1}}</th>
                                            <td>{{pan.type.libelle}}</td>
                                            <td>{{pan.qte}}</td>
                                            <td>{{pan.type.price}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Page end  -->
        </div>
    </div>
</template>
<script>
import axios from "axios";
import {URL_API} from '@/config'
// Import component
import Loading from 'vue-loading-overlay';
// Import stheet
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2'
import store  from '@/store'
import $ from "jquery"
export default {
    components: {
            Loading
    },
    data() {
        return {
            perms: [],
            permsi: [],
            user_perms: [],
            permissions: [],
            user:{},
            stored: store,
            packs:{},
            isLoading: false,
            fullPage: true,
            payload:{
                pack_id : null,
                montant : 0,
                user_id: this.$route.params.id
            },
            payload2:{
                permissions:[],
                user_id:null
            },
            initials: [],
            panier: [],
        };
    },
    mounted(){
        this.$store.state.user.permissions.forEach(element => {
            this.permsi.push(element.name)
        });
        this.getPermissions()
        this.getUser()
        this.getPacks()
        this.permissions = document.getElementsByName("perms")
    },
    methods:{
        modal(arg) {
            this.panier =  arg
            
        },
        assign(){
            this.permissions = document.getElementsByName("perms")
            console.log('perms',this.permissions.value)
            this.payload2.user_id = this.user.id
            for (let i = 0; i < this.permissions.length; i++) {
                if (this.permissions[i].checked == true) {
                    this.payload2.permissions.push(this.permissions[i].value)
                }
            }
            console.log(this.payload2.permissions);
            axios.post(URL_API+'assignPermission', this.payload2)
            .then(response => {
                console.log('rep_perms',response.data)
                if(response.data.status){
                    Swal.fire(
                        'Done !',
                        'Permission assigned.',
                        'success'
                    )
                }
                })
            .catch(error => {
                console.log(error)
                this.errored = true
                this.isLoading = false
            })
        },
        store() {
            this.$router.push('/edit-user/'+this.$route.params.id)
        },
        destroy(id) {
            Swal.fire({
                title: 'Do you want to delete this credit ?',
                text: "This action is irreversible!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.isLoading =  true
                    axios.delete(URL_API+'recharge/'+id)
                    .then(response => {
                        console.log(response.data)
                        this.isLoading =  false
                        Swal.fire(
                            'Deleted!',
                            'The credit was deleted.',
                            'success'
                        )
                        location.reload()
                    })
                    .catch(error => {
                        console.log(error)
                        this.errored = true
                        this.isLoading =  false
                    })
                    
                }
            })
            
        },
        recharger() {
            axios.post(URL_API+'recharge', this.payload)
            .then(function (reponse){
                console.log(reponse);
                if (reponse.data.status == true) {
                    Swal.fire('Successful', '', 'success')
                }   else{
                    Swal.fire('Warning', reponse.data.message, 'warning')
                }             
            })
            .catch(function (error){
                Swal.fire('Error', 'An error occurred while reloading', 'error')
                console.log(error);
            })
        },
        getUser() {
            this.isLoading = true
            
            axios.get(URL_API+'users/'+this.$route.params.id)
            .then(response => {
                console.log(response.data)
                if (response.data.status == true) {
                    $(document).ready( function () {
                            $('#tickets').DataTable(
                                {
                                    "lengthMenu": [ 5,10, 25, 50, 75, 100 ],
                                    "pageLength": 5,
                                    'order':[]
                                }
                            );
                        } );
                    $(document).ready( function () {
                            $('#example').DataTable(
                                {
                                    "lengthMenu": [ 5,10, 25, 50, 75, 100 ],
                                    "pageLength": 5,
                                    'order':[]
                                }
                            );
                        } );
                    this.user = response.data.data
                    response.data.data.permissions.forEach(element => {
                        this.perms.push(element.name)
                    });
                this.isLoading = false
                }
            })
            .catch(error => {
                Swal.fire('Error', 'An error occurred while retrieving data', 'error')
                console.log(error)
                this.errored = true
                this.isLoading = false
            })
        },
        getPermissions() {
            this.isLoading = true
            
            axios.get(URL_API+'getPermissions')
            .then(response => {
                
                if (response.data.status == true) {
                    this.initials = response.data.data
                    console.log('initials', this.initials);
                    this.isLoading = false
                }
            })
            .catch(error => {
                Swal.fire('Error', 'An error occurred while retrieving data', 'error')
                console.log(error)
                this.errored = true
                this.isLoading = false
            })
        },
        
        getPacks(){
            let app = this
            axios.get(URL_API+'packs')
            .then(function (reponse){
                console.log(reponse);
                if (reponse.data.status == true) {
                    app.packs = reponse.data.data
                }
            })
            .catch(function (error){
                console.log(error);
            })
        },
    }
}
</script>