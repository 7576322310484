<template>
    <div class="content-page">
        <div class="container-fluid add-form-list">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header d-flex justify-content-between">
                            <div class="header-title">
                                <h4 class="card-title">Scan a ticket</h4>
                            </div>
                        </div>
                        <div class="card-body">
                            <form @submit.prevent="store" data-toggle="validator">
                                <div class="row">
                                     
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Code *</label>
                                            <input v-model="product.code" id="code" type="password" class="form-control"  required>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>                                 
                                </div>                            
                            </form>
                            
                            <div class="col-md-12">
                                <button type="submit" class="btn btn-primary mr-2 float-right" @click="dayRecap">Récapitulatif</button>
                                <div class="table-responsive rounded mb-3" v-if="payload.from != '' && payload.to != ''">
                                    <table id="tbl_exporttable_to_xls" class="data-table table mb-0 tbl-server-info">
                                        <thead class="bg-white text-uppercase">
                                            <tr class="ligth ligth-data">
                                                <th>Meal type </th>
                                                <th>Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody class="ligth-body">
                                            <tr v-for="(bail,index) in bails" :key="index">
                                                <td>{{bail.libelle}}</td>
                                                <td>{{bail.qteTotal}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button v-if="perms.includes('export-tickets')" @click="ExportToExcel('xlsx')" class="btn mt-10 btn-primary mr-2">Export</button>
                                </div>
                                <div class="table-responsive rounded mb-3" v-else>
                                    <table id="tbl_exporttable_to_xls" class="data-table table mb-0 tbl-server-info">
                                        <thead class="bg-white text-uppercase">
                                            <tr class="ligth ligth-data">
                                                <th>Code</th>
                                                <th>Meal type </th>
                                                <th>Quantity</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody class="ligth-body">
                                            <tr v-for="(stock,index) in tickets" :key="index">
                                                <td>{{stock.code}}</td>
                                                <td>{{stock.type_repas.libelle}}</td>  
                                                <td> {{ stock.qte }} </td>
                                                <td v-if="stock.status == 1"> Used </td>  
                                                <td v-else>Not Used</td>                                             
                                            </tr>
                                        </tbody>
                                    </table>
                                        <div>
                                            &nbsp;
                                        </div>
                                    <button v-if="perms.includes('export-tickets')" class="btn mt-10 btn-primary mr-2" @click="ExportToExcel('xlsx')">Export</button>
                                </div>
                            </div>
                            
                </div>
            </div>
                    <div class="vld-parent">
                        <loading :active.sync="isLoading" 
                        :can-cancel="true" 
                        :is-full-page="fullPage"></loading>
                    </div>
                </div>
            </div>
            <!-- Page end  -->
        </div>
    </div>
</template>
<script>
import * as XLSX from 'xlsx/xlsx.mjs'
import axios from "axios";
import {URL_API} from '@/config'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2'
import $ from "jquery"
import moment from 'moment'
moment.locale('fr')
export default {
    components: {
            Loading
    },
    data() {
        return {
            perms: [],
            product:{
                code: ''
            },
            isLoading: false,
            fullPage: true,
            tickets: {},
            bails: [],
            cout:0,
            payload:{
                from: '',
                to: '',
                expired: 0,
                valid: 0,
            },
        };
    },
    mounted(){this.$store.state.user.permissions.forEach(element => {
            this.perms.push(element.name)
        });
        console.log('test',this.payload)
        document.getElementById("code").focus();
        this.getTickets()
    },
    methods:{

        store() {
            this.isLoading = true
            axios.post(URL_API+'valider-ticket', this.product)
            .then(response => {
                console.log(response.data)
                this.isLoading = false
                
                if (response.data.status == true) {
                    Swal.fire(
                        response.data.message,
                         '',
                        'success'
                    )
                    this.product.code = ''
                    document.getElementById("code").focus();
                    this.getTickets()
                }
                else{
                    Swal.fire(
                        
                        response.data.message ,
                        '',
                        'error'
                    )
                    this.product.code = ''
                    document.getElementById("code").focus();
                }
            })
            .catch(error => {
                console.log(error)
                this.errored = true
                this.isLoading = false
            })
        },
       getTickets(){
           axios.get(URL_API+'ticket-du-jour')
           .then(response => {
                console.log(response.data)
                this.isLoading = false
                
                if (response.data.status == true) {
                    $(document).ready( function () {
                            $('#tbl_exporttable_to_xls').DataTable(
                                {
                                    "lengthMenu": [ 5,10, 25, 50, 75, 100,500 ],
                                    "pageLength": 10,
                                    'order':[],
                                    stateSave: true,
                                    "bDestroy": true
                                }
                            );
                        } );
                    this.tickets = response.data.data
                }
                
            })
            .catch(error => {
                console.log(error)
                this.errored = true
                this.isLoading = false
            })
       },
       dayRecap() {
        this.payload.from = moment(new Date).format('YYYY-MM-DD')
        this.payload.to = moment(new Date).format('YYYY-MM-DD')

        console.log(this.payload)
        console.log('test')
            this.isLoading =  true
            axios.post(URL_API+'calcul-ticket', this.payload)
            .then(reponse => {
                console.log('bails', reponse)
                if (reponse.data.status == true) {
                    this.bails = reponse.data.data
                    this.cout = reponse.data.cout
                    if (this.bails.length == 0) {
                        Swal.fire('Note', 'No expired ticket in this period!', 'info')
                    }
                }
            })
            .catch(error => {
                console.log(error)
                this.errored = true
                
            })
            this.isLoading = false
        },
       ExportToExcel(type, fn, dl) {
       var elt = document.getElementById('tbl_exporttable_to_xls');
       var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
       return dl ?
         XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
         XLSX.writeFile(wb, fn || ('Sells sheet - '+ new Date() +'.' + (type || 'xlsx')));
    },
    }
}
</script>