<template>
    <div class="content-page">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
                        <div>
                            <h4 class="mb-3">top ups</h4>
                        </div>
                        <div class="row" >
                            From : <input class="form-control col-lg-4" @change="first" type="date" v-model="payload.from" name="" id="">
                            To : <input class="form-control col-lg-4" @change="second" type="date" v-model="payload.to" name="" id="">
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="table-responsive rounded mb-3">
                    <table id="example" class="data-table table mb-0 tbl-server-info" v-if="payload.from != '' && payload.to != ''">
                        <thead class="bg-white text-uppercase">
                            <tr class="ligth ligth-data">
                                <th>#</th>
                                <th>Beneficiary</th>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Credit By</th>
                                <th v-if="permissions.includes('delete-recharge')">Action</th>
                            </tr>
                        </thead>
                        <tbody class="ligth-body">
                            <tr v-for="(recharge,index) in recharges" :key="index">
                                <td v-if="recharge.user != null">{{ index +1}}</td>
                                <td v-if="recharge.user != null">{{ recharge.user.nom +' '+recharge.user.prenoms }}</td> 
                                <td v-if="recharge.user != null">{{ recharge.montant }} XOF</td>  
                                <td v-if="recharge.user != null">{{ recharge.created_at }}</td> 
                                <td v-if="recharge.created_by != null">{{ recharge.created_by.nom +' '+recharge.created_by.prenoms }}</td>
                                <td v-if="permissions.includes('delete-recharge')">
                                    <div class="d-flex align-items-center list-action">
                                        <button v  class="badge badge-info bg-warning mr-2 btn" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete"
                                            @click="destroy(recharge.id)"><i class="fa fa-trash mr-0"></i>
                                        </button>
                                    </div>
                                </td> 
                            </tr>
                        </tbody>
                    </table>
                    <div class="table-responsive rounded mb-3" v-else>
                        <table id="example"  class="data-table table mb-0 tbl-server-info">
                            <thead class="bg-white text-uppercase">
                                <tr class="ligth ligth-data">
                                    <th>Label</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody class="ligth-body">
                                <tr v-for="(bail,index) in label" :key="index">
                                    <td>{{bail}}</td>
                                    <td>{{value[index]}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
            <!-- Page end  -->
        </div>
        <!-- Modal Edit -->
        <div class="vld-parent">
                <loading :active.sync="isLoading" 
                :can-cancel="true" 
                :is-full-page="fullPage"></loading>
            </div>
    </div>
</template>
<script>
import axios from "axios";
import {URL_API} from '@/config'
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2'
import $ from "jquery"
import moment from 'moment'
moment.locale('fr')

export default {
    components: {
            Loading
    },
    data() {
        return {
            permissions: [],
            recharges:[],
            isLoading: false,
            fullPage: true,
            payload:{},
            recap:null,
            cout:0,
            label:[],
            value:[],
        };
    },
    mounted(){this.$store.state.user.permissions.forEach(element => {
            this.permissions.push(element.name)
        });
        this.getPacks()
    },
    methods:{
        getPacks(){
            let app = this
            app.isLoading = true
            axios.get(URL_API+'recharge')
            .then(function (reponse){
                console.log(reponse);
                if (reponse.data.status == true) {
                    $(document).ready( function () {
                            $('#example').DataTable(
                                {
                                    "lengthMenu": [ 5,10, 25, 50, 75, 100 ],
                                    "pageLength": 10,
                                    'order':[],
                                    stateSave: true,
                                    "bDestroy": true
                                }
                            );
                        } );
                    app.recharges = reponse.data.data
                    app.recharges.forEach(element => {
                        element.created_at = moment(element.created_at).format('DD-MM-YYYY H:m')
                    });
                }
                
            })
            .catch(function (error){
                console.log(error);
            })
            app.isLoading = false
        },
        first() {
            if (this.payload.from != '') {
                if (this.payload.to != '') {
                    this.getRechargeRecap()
                } else {
                    Swal.fire('Note', 'Enter end date.', 'info')
                }
            } else {
                Swal.fire('Note', 'Enter begin date.', 'info')
            }
        },
        second() {
            if (this.payload.to != '') {
                if (this.payload.from != '') {
                    this.getRechargeRecap()
                } else {
                    Swal.fire('Note', 'Enter begin date.', 'info')
                }
            } else {
                Swal.fire('Note', 'Enter end date.', 'info')
            }
        },
        getRechargeRecap() {
            this.isLoading =  true
            axios.post(URL_API+'rechargeRecap', this.payload)
            .then(reponse => {
                console.log('recap', reponse)
                if (reponse.data.status == true) {
                    this.label = Object.keys(reponse.data.data)
                    this.value = Object.values(reponse.data.data)

                    console.log(this.label,this.value)
                    this.recap = reponse.data.data
                    this.cout = reponse.data.cout
                    if (this.recap.length == 0) {
                        Swal.fire('Note', 'No ticket printed in this period!', 'info')
                    }
                }
            })
            .catch(error => {
                console.log(error)
                this.errored = true
                
            })
            this.isLoading = false
        },
        destroy(id) {
            Swal.fire({
                title: 'Do you want to delete this credit ?',
                text: "This action is irreversible!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, deleted!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.isLoading =  true
                    axios.delete(URL_API+'recharge/'+id)
                    .then(response => {
                        console.log(response.data)
                        this.isLoading =  false
                        Swal.fire(
                            'Deleted!',
                            'The credit was deleted.',
                            'success'
                        )
                        location.reload()
                    })
                    .catch(error => {
                        console.log(error)
                        this.errored = true
                        this.isLoading =  false
                    })
                    
                }
            })
            
        },
    }
}
</script>